import Photo from "../../components/design_page/photo/photo";
import React, {useEffect, useState} from "react";
import Price from "../../components/design_page/price/price";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {addProductToCart, getDesign, getProduct} from "../../store/api-action";
import {onloadHandler} from "../loader/loader";

export default function ProductMobilePage() {

    const { product_id } = useParams();
    const dispatcher = useAppDispatch();

    const product = useAppSelector(( state ) => state.product);

    const [size, setSize] = useState('');

    useEffect(()=>{
        document.title = 'MKY | Каталог аниме одежды';
        if (product_id) {
            dispatcher(getProduct({product_id: Number(product_id)}));
        }
    }, []);

    function addToCartHandler (e: any) {
        if (product) dispatcher(addProductToCart({id: product.id, size: size}));
        e.target.classList.add('button_waiting_query_result');
    }

    function ChangeClothingSize (evt:any) {
        setSize(evt.target.value);
    }

    function validateSelectors () {
        return size !== '';
    }

    if (product) {
        setTimeout(onloadHandler, 500);
        return (
            <div className="loading" onLoad={onloadHandler}>
                <section className="design_mobile_photo_carusel">
                    <Photo product_images={[product.main_image, product.design_image, ...product.images, product.size_table_image]} />
                </section>
                <section className="design_mobile_data_section">
                    <div>
                        <h1 className="black">{product.name}</h1>
                        <p className="mt-3 design_description">{product.description}</p>
                        <select className="design_page_selector" id="wear" value="0" disabled>
                            <option value="0" disabled>Выбор одежды</option>
                        </select> <br/>

                        <select className="design_page_selector" id="size" value="0" disabled>
                            <option value="0" disabled>Выбор размера дизайна</option>
                        </select> <br/>

                        <select className="design_page_selector" id="place" value="0" disabled>
                            <option value="0" disabled>Выбор места</option>
                        </select> <br/>
                        <form className="size_selector_form">
                            {
                                product.sizes.map((sizeButton, index) => {
                                    return <div>
                                        <input
                                            type="radio"
                                            name="size"
                                            value={sizeButton} id={sizeButton}
                                            className="hidden"
                                            checked={sizeButton === size}
                                            onChange={ChangeClothingSize}
                                        />
                                        <label htmlFor={sizeButton}>
                                            <div className={`size_container `}>{sizeButton}</div>
                                        </label>
                                    </div>
                                })
                            }
                        </form>
                    </div>
                    <div className="design_page_summary">
                        <Price product_price={product.price} />
                        <button
                            className="cart_form"
                            disabled={!validateSelectors()}
                            onClick={addToCartHandler}
                        >
                            Добавить в корзину
                        </button>
                    </div>
                </section>
            </div>
        );
    }
    return <div/>
}