import {CreateOrderSteps} from "../../types/types";
import {CreateOrderStep} from "../../const/const";

export default function ProgressBar( data: { step: CreateOrderSteps } ) {
    return (
        <section className="order_progress">
            <div className={data.step >= CreateOrderStep.personality ? "order_progress__step active" : "order_progress__step"}>
                1
            </div>
            <div className={data.step >= CreateOrderStep.delivery ? "order_progress__line active" : "order_progress__line"}></div>
            <div className={data.step >= CreateOrderStep.delivery ? "order_progress__step active" : "order_progress__step"}>
                2
            </div>
            <div className={data.step >= CreateOrderStep.checkData ? "order_progress__line active" : "order_progress__line"}></div>
            <div className={data.step >= CreateOrderStep.checkData ? "order_progress__step active" : "order_progress__step"}>
                3
            </div>
            <div className={data.step >= CreateOrderStep.payment ? "order_progress__line active" : "order_progress__line"}></div>
            <div className={data.step >= CreateOrderStep.payment ? "order_progress__step active" : "order_progress__step"}>
                4
            </div>
        </section>
    );
}











