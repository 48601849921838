import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import React, {useEffect, useState} from "react";
import {addProductToCart, getProduct} from "../../store/api-action";

import {onloadHandler} from "../loader/loader";
import Photo from "../../components/design_page/photo/photo";
import Price from "../../components/design_page/price/price";
import {Svg} from "../../components/svg/svg";


export default function ProductPage() {

    const { product_id } = useParams();
    const dispatcher = useAppDispatch();

    const product = useAppSelector(( state ) => state.product );
    const mobileHandler = () => {
        const block = document.querySelector('.design_page_content_container');
        if (block?.classList.contains('active_photo')) {
            block.classList.remove('active_photo');
        } else {
            block?.classList.add('active_photo');
        }
    }

    const [size, setSize] = useState('');

    useEffect(()=>{
        document.title = 'MKY | Каталог аниме одежды';
        if (product_id) {
            dispatcher(getProduct({product_id: Number(product_id)}));
        }
    }, []);

    function addToCartHandler (e: any) {
        if (product) dispatcher(addProductToCart({id: product.id, size: size}));
        e.target.classList.add('button_waiting_query_result');
    }

    function ChangeClothingSize (evt:any) {
        setSize(evt.target.value);
    }

    function validateSelectors () {
        return size !== '';
    }


    if (product) {
        setTimeout(onloadHandler, 500);
        return (
            <div className="main_container loading" onLoad={onloadHandler}>
        <div className="design_page_main_container">
        <div className="design_page_brand_columns">
            <Svg type={"mky"}/>
            <Svg type={"mky"}/>
            <Svg type={"mky"}/>
            <Svg type={"mky"}/>
            <Svg type={"mky"}/>
            <Svg type={"mky"}/>
            </div>
            <Photo product_images={[product.main_image, product.design_image, ...product.images, product.size_table_image]} />
        <div className="design_page_content_container active_photo">
        <div className="mobile-helper-button" onClick={mobileHandler}></div>
        <div>

        <h1 className="black">{ product.name }</h1>
        <p className="mt-3 design_description">{ product.description }</p>

        <select className="design_page_selector" id="wear" disabled value="0">
            <option value="0"  disabled>{product.clothes_name}</option>
        </select> <br/>

        <select className="design_page_selector" id="size" disabled value="0">
            <option value="0" disabled>Выбор размера дизайна</option>
        </select> <br/>

        <select className="design_page_selector" id="place" disabled value="0">
            <option value="0" disabled>Выбор места</option>
        </select> <br/>
        <form className="size_selector_form">
            {
                product.sizes.map((sizeButton, index) => {
                    return  <div key={"sizeButton " + index}><input type="radio" name="size"
                                    value={sizeButton} id={sizeButton}
                                    className="hidden"
                                    checked={sizeButton === size}
                                    onChange={ChangeClothingSize}
                                />
                                <label htmlFor={sizeButton}>
                                    <div className={`size_container `}>{sizeButton}</div>
                                </label>
                    </div>
                })
            }
            </form>
            </div>
            <div className="design_page_summary">
                <Price product_price={ product.price }/>
                <button className="cart_form" disabled={!validateSelectors()} onClick={addToCartHandler}>Добавить в корзину</button>
            </div>
            </div>
            <div className="design_page_brand_columns">
                <Svg type={"mky"}/>
                <Svg type={"mky"}/>
                <Svg type={"mky"}/>
                <Svg type={"mky"}/>
                <Svg type={"mky"}/>
                <Svg type={"mky"}/>
            </div>
            </div>
            </div>
    );
    }
    return <div />
}