import {UserInfo} from "../../types/types";

export default function CreateOrderCheckData( data: { userConfig: UserInfo | undefined } ) {

    if (!data.userConfig) {
        return <div />
    }

    if (!data.userConfig.delivery || !data.userConfig.delivery.deliveryService) {
        return <div />
    }

    return (
        <section>
            <div>
                <h4 className="black">Данные получателя:</h4>
                <p>
                    <span className="black bold">ФИО: </span>
                    {data.userConfig.lastName} {data.userConfig.firstName} {data.userConfig.middleName}
                </p>
                <p><span className="black bold">Номер телефона: </span>{data.userConfig.number}</p>
                <p><span className="black bold">Email: </span>{data.userConfig.email}</p>
            </div>
            <div className="mt-3">
                <h4 className="black">Информация о доставке:</h4>
                <p><span className="black bold">Сервис: </span>{data.userConfig.delivery.deliveryService}</p>
                <p><span className="black bold">Регион: </span>{data.userConfig.delivery.regionTo}</p>
                <p><span className="black bold">Город: </span>{data.userConfig.delivery.cityTo}</p>
                <p><span className="black bold">Адрес: </span>{data.userConfig.delivery.addressTo}</p>
                <p><span className="black bold">Индекс: </span>{data.userConfig.delivery.indexTo}</p>
            </div>
        </section>
    );
}