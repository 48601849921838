import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import React, {useEffect, useState} from "react";
import {addDesignToCart, getDesign} from "../../store/api-action";
import {onloadHandler} from "../loader/loader";
import Price from "../../components/design_page/price/price";
import Photo from "../../components/design_page/photo/photo";
import {Svg} from "../../components/svg/svg";


export default function DesignPage() {

    const { design_id } = useParams();
    const dispatcher = useAppDispatch();

    const thisDesign = useAppSelector(( state ) => state.design );

    const mobileHandler = () => {
        const block = document.querySelector('.design_page_content_container');
        if (block?.classList.contains('active_photo')) {
            block.classList.remove('active_photo');
        } else {
            block?.classList.add('active_photo');
        }
    }

    const [params, setParams] = useState({ size: '', clothes_id: 0, design_size: 0, design_place: 0, design_id: 0});

    useEffect(()=>{
        document.title = 'MKY | Каталог аниме одежды';
        if (design_id) {
            dispatcher(getDesign({design_id: Number(design_id)}));
            setParams({...params, design_id:  Number(design_id)})
        }
    }, []);

    function addDesignToCartHandler (e: any) {
        dispatcher(addDesignToCart(params));
        e.target.classList.add('button_waiting_query_result');
    }

    function changeClothing (evt: any) {
        setParams({
            ...params,
            clothes_id: Number(evt.target.value),
            design_place: 0,
            size: ''
        });
    }

    function changeSize (evt: any) {
        setParams({
            ...params,
            design_size: Number(evt.target.value),
        });
    }

    function changePlace (evt: any) {
        setParams({
            ...params,
            design_place: Number(evt.target.value),
        });
    }

    function ChangeClothingSize (evt:any) {
        setParams({
            ...params,
            size: evt.target.value
        })
    }

    function validateSelectors () {
        if (params.clothes_id !== 0 && params.design_size !== 0 && params.design_place !== 0 && params.size !== '') {
            return true
        }
        return false;
    }

    if (thisDesign !== undefined) {
        const result_place_variants = thisDesign.place_variants.filter(place => place.clothes_id == params.clothes_id);

        const clothing_sizes: string[] = [];

        if (params.clothes_id !== 0) {
            thisDesign.clothes.map((clothing) => {
                if (clothing.id == params.clothes_id) {
                    clothing.sizes.map((size) => {
                        clothing_sizes.push(size);
                    });
                }
            });
        } else {
            thisDesign.clothes.map((clothing) => {
                clothing.sizes.map((size) => {
                    if (!clothing_sizes.includes(size)) {
                        clothing_sizes.push(size);
                    }
                });
            });
        }
        setTimeout(onloadHandler, 500);
        return (
            <div className="main_container loading" onLoad={onloadHandler}>
                <div className="design_page_main_container">
                    <div className="design_page_brand_columns">
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                    </div>
                    <Photo params={params} design_data={thisDesign}/>
                    <div className="design_page_content_container active_photo">
                        <div className="mobile-helper-button" onClick={mobileHandler}></div>
                        <div>
                            <h1 className="black">{ thisDesign.name }</h1>
                            <p className="mt-3 design_description">{ thisDesign.description }</p>
                            <select className="design_page_selector" id="wear" value={params.clothes_id} onChange={changeClothing}>
                                <option value="0" disabled>Выбор одежды</option>
                                {
                                    thisDesign.clothes.map((clothing) => {
                                        return <option value={clothing.id} key={"clothes_id_" + clothing.id}> { clothing.name } </option>;
                                    })
                                }
                            </select> <br/>

                            <select className="design_page_selector" id="size" value={params.design_size} onChange={changeSize}>
                                <option value="0" disabled>Выбор размера дизайна</option>
                                {
                                    thisDesign.size_variants.map((variant) => {
                                        if (params.clothes_id !== 0) {
                                            return <option value={variant.id} key={"size_id_" + variant.id}>{variant.name}</option>;
                                        }
                                    })
                                }
                            </select> <br/>

                            <select className="design_page_selector" id="place" value={params.design_place} onChange={changePlace}>
                                <option value="0" disabled>Выбор места</option>
                                {
                                    result_place_variants.map((variant) => {
                                        if (params.clothes_id !== 0) {
                                            return <option value={variant.id} key={"place_id_" + variant.id }>{variant.name}</option>;
                                        }
                                    })
                                }
                            </select> <br/>
                            <form className="size_selector_form">
                                {
                                    clothing_sizes.map((size) => {
                                        return  <div>
                                            <input
                                                type="radio"
                                                name="size"
                                                value={size}
                                                id={size}
                                                className="hidden"
                                                checked={params.size === size}
                                                onChange={ChangeClothingSize}
                                            />
                                            <label htmlFor={size}>
                                                <div className={`size_container `}>
                                                    {size}
                                                </div>
                                            </label>
                                        </div>
                                    })
                                }
                            </form>
                        </div>
                        <div className="design_page_summary">
                            <Price params={params} design_data={thisDesign}></Price>
                            <button className="cart_form" disabled={!validateSelectors()} onClick={addDesignToCartHandler}>Добавить в корзину</button>
                        </div>
                    </div>
                    <div className="design_page_brand_columns">
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                        <Svg type={"mky"}/>
                    </div>
                </div>
            </div>
        );
    }
    return <div />
}