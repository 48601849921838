import { reducer } from './reducer';
import {configureStore} from '@reduxjs/toolkit';
import {createAPI} from '../api/api';
import {LOCAL_STORAGE_NAMES} from "../const/const";
import {SizeType} from "../types/types";

export const api = createAPI();

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    }),
});

export const getLocalStorage = () => {
    const data = window.localStorage.getItem(LOCAL_STORAGE_NAMES.cart);
    if (data) {
        // return JSON.parse(data);
        return [];
    }

    return null;
};

export const addToLocalStorage = (elemId:number) => {
    const data = getLocalStorage();
    if (data === null || data.length === 0) {
        const cart = [{
            count: 1,
            id: elemId
        }]

        window.localStorage.setItem(LOCAL_STORAGE_NAMES.cart, JSON.stringify(cart));
        return cart;
    }

    const cart:SizeType[] = [];
    let isset = false;
    data.map((cartElem: SizeType) => {
        if (cartElem.id === elemId) {
            isset = true;
            cartElem.count += 1;
        }
        cart.push(cartElem);
    })
    if (!isset) {
        cart.push({ id: elemId, count: 1 });
    }
    window.localStorage.setItem(LOCAL_STORAGE_NAMES.cart, JSON.stringify(cart));
    return cart;
}

export const removeFromLocalStorage = (id:number, all = false) => {
    const data = getLocalStorage();
    const cart: SizeType[] = [];

    if (data !== null && data.length > 0) {
        data.map((offer: SizeType) => {
            if (offer.id === id ) {
                if (!all) {
                    const newCount = offer.count - 1;
                    if (newCount !== 0) {
                        cart.push({id: id, count: newCount});
                    }
                }
            } else {
                cart.push(offer);
            }
        });
    }

    window.localStorage.setItem(LOCAL_STORAGE_NAMES.cart, JSON.stringify(cart));
    return cart;
}
