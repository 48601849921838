import {GlobalAppRoutes, InstagramURL, TelegramURL, VkontakteURL, YouTubeURL} from "../../const/const";

export default function Footer () {
    return (
        <footer className="site-footer">
            <div className="header_container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-8 footer_column_left">
                                <h2 className="footer-heading mb-4">О нас</h2>
                                <p>MKY это молодой бренд одежды, основывающийся на дизайнерской компьютерной вышивке. Мы создаем дизайны по мотивам ваших любимых аниме, мультиков и сериалов.</p>
                            </div>
                            <div className="footer_column_right">
                                <div className="col-md-4">
                                    <ul className="list-unstyled ml-auto">
                                        {/*<li><a href="#">О нас</a></li>*/}
                                        <li><a href={GlobalAppRoutes.faq}>FAQ</a></li>
                                        <li><a href={ VkontakteURL }>VK</a></li>
                                        <li><a href={ TelegramURL }>Telegram</a></li>
                                        <li><a href={ InstagramURL }>Instagram</a></li>
                                        <li><a href={ YouTubeURL }>YouTube</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="list-unstyled ml-auto">
                                        <li><a href={GlobalAppRoutes.privacyPolicy}>Политика конфиденциальности</a></li>
                                        <li><a href={GlobalAppRoutes.publicOffer}>Публичная оферта</a></li>
                                        <li><a href="mailto:support@mky-shop.ru">support@mky-shop.ru</a></li>
                                        <li>Самозанятый - Малышев Артур Константинович</li>
                                        <li>ИНН - 165723107203</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
