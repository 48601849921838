import GeneratedElemPreview from "../generated_item_preview/generated_elem_preview";
import {imageZoomIn} from "../../global_functions/image_zoomer";
import {useAppSelector} from "../../hooks/redux/redux";
import {errorState} from "../../const/const";
import {store} from "../../store";
import {getBestProduct} from "../../store/api-action";
import {useEffect} from "react";

export default function FirstPageProductPreview() {

    useEffect(() => {
        store.dispatch(getBestProduct());
    }, []);

    const product = useAppSelector(state => state.bestProduct);

    if (!product || product === errorState) {
        return (
            <div className="first_page__popular_item_preview">
                <section className="first_page__item">
                    <GeneratedElemPreview/>
                </section>
                <div className="empty_first_page__item__live_photo"/>
            </div>
        )
    }

    return (
        <div className="first_page__popular_item_preview">
            <section className="first_page__item">
                <GeneratedElemPreview offerData={product}/>
            </section>
            <div className="first_page__item__live_photo">
                <div className="first_page__item__live_photo__block">
                    <img
                        src={product.horizontal_image}
                        alt={product.name}
                        id="firstPage popularItemTemplate"
                        onLoad={imageZoomIn("firstPage popularItemTemplate", "firstPage popularItemTemplate zoomer")}
                    />
                    <div className="image_mouse_zoomer" id="firstPage popularItemTemplate zoomer"/>
                </div>
            </div>
        </div>
    );
}
