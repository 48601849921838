import {
    AppNotification, AppRoute,
    CreateOrderStep,
    EmailRegularExpression,
    GlobalAppRoutes,
    NameRegularExpression,
    NumberAllowedSymbols
} from "../../const/const";
import {useState} from "react";
import {CreateOfferDataType, CreateOrderSteps} from "../../types/types";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {createOrderNextStep, createOrderPrevStep} from "../../store/action";
import appNotification from "../../global_functions/notify";
import {createOrder} from "../../store/api-action";

export default function CreateOrderButtons ( data: { step: CreateOrderSteps } ) {

    const dispatcher = useAppDispatch();
    const [policyAccept, setPolicyAccept] = useState(false);
    const userConfig = useAppSelector(state => state.userConfig);

    function validatePersonality () {
        const nameValidator = (name: string) => {
            const matches = name.match(NameRegularExpression);
            if ( name.length !== 0 && matches && matches[0] ) {
                return true;
            }

            return false;
        }

        const emailValidator = (email: string) => {
            const matches = email.match(EmailRegularExpression);
            if ( email.length !== 0 && matches && matches[0] ) {
                return true;
            }
            return false;
        }

        const numberValidator = (number: string) => {
            const allowedSymbols = NumberAllowedSymbols;
            const array = number.split('');

            array.map(( symbol ) => {
                if (!allowedSymbols.includes(symbol)) {
                    return false;
                }
            });

            return number.length === 12;
        }

        if (userConfig) {
            let errorCounter = 0;
            if (!nameValidator(userConfig.firstName)) {
                appNotification(AppNotification.info, "Поле \"Имя\" заполнено неправильно!");
                errorCounter++;
            }
            if (!nameValidator(userConfig.middleName)) {
                appNotification(AppNotification.info, "Поле \"Отчество\" заполнено неправильно!");
                errorCounter++;
            }
            if (!nameValidator(userConfig.lastName)) {
                appNotification(AppNotification.info, "Поле \"Фамилия\" заполнено неправильно!");
                errorCounter++;
            }
            if (!emailValidator(userConfig.email)) {
                appNotification(AppNotification.info, "Поле \"Email\" заполнено неправильно!");
                errorCounter++;
            }
            if (!numberValidator(userConfig.number)) {
                appNotification(AppNotification.info, "Поле \"Номер телефона\" заполнено неправильно!");
                errorCounter++;
            }
            if (errorCounter > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            appNotification(AppNotification.info, "Заполните данные получателя!");
            return false;
        }
    }

    function validateDelivery () {
        if (!userConfig?.delivery?.deliveryService || !userConfig?.delivery?.addressTo) {
            appNotification(AppNotification.info, "Необходимо выбрать службу доставки!");
            return false;
        }
        return true;
    }

    function nextStep (evt: any) {
        evt.preventDefault();
        switch (data.step) {
            case CreateOrderStep.personality:
                if (validatePersonality()) {
                    dispatcher(createOrderNextStep());
                }
                break;
            case CreateOrderStep.delivery:
                if (validateDelivery()) {
                    dispatcher(createOrderNextStep());
                }
                break;
            case CreateOrderStep.checkData:
                if (userConfig && userConfig.delivery) {
                    const preparedOrderData: CreateOfferDataType = {
                        first_name: userConfig.firstName,
                        middle_name: userConfig.middleName,
                        last_name: userConfig.lastName,
                        email: userConfig.email,
                        phone: userConfig.number,
                        delivery: {
                            delivery_service: userConfig.delivery.deliveryService,
                            region: userConfig.delivery.regionTo,
                            city: userConfig.delivery.cityTo,
                            address: userConfig.delivery.addressTo,
                            zip: userConfig.delivery.indexTo,
                            type: userConfig.delivery.mailType,
                            pvz_type: userConfig.delivery.pvzType,
                            track: "",
                            description: userConfig.delivery.deliveryDescription?.description,
                            price: userConfig.delivery.cashOfDelivery,
                            comment: userConfig.delivery.comment
                        },
                    }
                    dispatcher(createOrder(preparedOrderData))
                }
                dispatcher(createOrderNextStep());
                break;
            case CreateOrderStep.payment:
                window.location.pathname = AppRoute.main;
                break;
            default:
                alert("Недопустимое значение 'data.step' = " + data.step + "! ..../pages/create_order/buttons.tsx");
                break;
        }
    }

    function prevStep (evt: any) {
        evt.preventDefault();
        switch (data.step) {
            case CreateOrderStep.personality:
                window.location.pathname = AppRoute.cart;
                break;
            case CreateOrderStep.delivery:
                dispatcher(createOrderPrevStep());
                break;
            case CreateOrderStep.checkData:
                dispatcher(createOrderPrevStep());
                break;
            case CreateOrderStep.payment:
                alert("Недопустимое значение 'data.step' = " + data.step + "! ..../pages/create_order/buttons.tsx");
                break;
            default:
                alert("Недопустимое значение 'data.step' = " + data.step + "! ..../pages/create_order/buttons.tsx");
                break;
        }
    }


    if (data.step === CreateOrderStep.checkData) {
        return (
            <div>
                <div className="policyAccept">
                    <input type="checkbox" checked={policyAccept} onChange={() => {
                        setPolicyAccept(!policyAccept)
                    }}/>
                    <a href={GlobalAppRoutes.publicOffer}>Я согласен с условиями предоставления сервиса</a>
                </div>

                <div className="create_order__buttons">
                    <button className="cart_form" onClick={prevStep}>Назад</button>
                    <button className="cart_form" onClick={nextStep} disabled={!policyAccept} >Оплатить</button>
                </div>
            </div>
        );
    }

    if (data.step === CreateOrderStep.payment) {
        return (
            <button className="cart_form" onClick={nextStep}>На главную</button>
        )
    }

    return (
        <div className="create_order__buttons">
            <button className="cart_form" onClick={prevStep}>Назад</button>
            <button className="cart_form" onClick={nextStep}>Далее</button>
        </div>
    )
}