export default function FaviconHandler () {
    const icon = document.querySelector('#favicon');

    function changeIcon () {

        icon?.setAttribute('href', 'favicon.ico');

        setTimeout(()=>{
            icon?.setAttribute('href', 'favicons/favicon_m.ico');
        },1500);

        setTimeout(()=>{
            icon?.setAttribute('href', 'favicons/favicon_k.ico');
        },2500);

        setTimeout(()=>{
            icon?.setAttribute('href', 'favicons/favicon_y.ico');
        },3500);

        setTimeout(()=>{
            icon?.setAttribute('href', 'favicons/favicon_black.ico');
        },4500);
    }

    changeIcon();

    setInterval(()=>{
        changeIcon();
    },5500);
}
