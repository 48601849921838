import {AppRoute} from "../../const/const";

export default function EmptyCart () {
    return (
        <div className="main_container">
            <h1 className="cart_h1">Корзина</h1>
            <main className="cart">
                <div className="empty_cart">
                    <h1 style={{textAlign: "center"}}>Ваша корзина пуста</h1>
                    <input type="button" className="cart_form cart_confirm-button" value="Перейти к покупкам" onClick={ () => { window.location.assign(AppRoute.offerList); } }/>
                </div>
            </main>
        </div>
    );
}
