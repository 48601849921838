import {useEffect} from "react";
import {CLIENT_URL} from "../../../const/const";


export default function PublicOffer () {
    useEffect(() => {
        document.title = 'MKY | Публичная оферта';
    }, []);

    return (
        <div className="main_container">
            <h1 className="cart_h1">Публичная оферта</h1>
            <div className="faq_container">
                <p className="document">Интернет-магазин MKY, расположенный под доменным адресом (именем) <a href={ CLIENT_URL }>mky-shop.ru</a> (далее - Продавец), в лице самозанятого Малышев Артур Константинович, публикует настоящий договор, являющийся публичным договором-офертой в адрес как физических, так и юридических лиц (далее - Покупатель) о нижеследующем:</p>
                <p className="document">Статья 1. Предмет договора-оферты</p>
                <p className="document">1.1. Продавец обязуется передать в собственность Покупателю, а Покупатель обязуется оплатить и принять заказанные в интернет-магазине <a href={ CLIENT_URL }>https://mky-shop.ru/</a> товары (далее - Товар), в указанном количестве и по указанной цене.</p>
                <p className="document">Статья 2. Момент заключения договора.</p>
                <p className="document">2.1. Текст данного Договора является публичной офертой (в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса РФ).</p>
                <p className="document">2.2. Факт оформления Заказа Товара у Продавца как самостоятельно, так и через оператора, является безоговорочным принятием данного Договора, и Покупатель рассматривается как лицо, вступившее с Продавцом в договорные отношения.</p>
                <p className="document">2.3. Заказчик производит Акцепт Оферты, оформив Заказ на сайте <a href={ CLIENT_URL }>MKY</a> , указав все запрашиваемые персональные данные, ознакомившись с условиями, и дав свое согласие на них (Путем клика на бокс около ссылки «Я согласен с условиями предоставления сервиса»).</p>
                <p className="document">2.4. Совершая Акцепт Оферты, Заказчик дает согласие на использование своих персональных данных интернет-магазином <a href={ CLIENT_URL }>MKY</a>, курьерскими службами, выполняющими заказ, а также платежными он-лайн системами, через которые происходит оплата Заказа.</p>
                <p className="document">2.5. Акцепт Оферты Заказчиком создает Договор (статья 438 Гражданского Кодекса РФ) на условиях Оферты.</p>
                <p className="document">2.6. Договор вступает в силу с момента Акцепта Оферты Заказчиком и действует в течение одного года.</p>
                <p className="document">2.7. Заказчик соглашается и признает, что внесение изменений в Оферту влечет за собой внесение этих изменений в заключенный и действующий между Заказчиком и Исполнителем Договор, и эти изменения в Договор вступают в силу одновременно с такими изменениями в Оферте.</p>
                <p className="document">2.8. Оформление Заказа Товара и расчета осуществляется путем заказа Покупателем в интернет-магазине <a href={ CLIENT_URL }>MKY</a> .</p>
                <p className="document">Статья 3. Характеристики товара</p>
                <p className="document">3.1. В связи с разными техническими характеристиками мониторов цвет Товара может отличаться от представленного на сайте.</p>
                <p className="document">3.2. Характеристики и внешний вид Товара могут незначительно отличаться от описанных на сайте.</p>
                <p className="document">3.3. Макеты Товаров, представленных в интернет-магазине, могут незначительно отличаться от физического Товара.</p>
                <p className="document">Статья 4. Стоимость товара</p>
                <p className="document">4.1. Цены в интернет-магазине указаны в Российских рублях.</p>
                <p className="document">4.2. Тарифы на оказание услуг по доставке Товара указаны в интернет-магазине <a href={ CLIENT_URL }>MKY</a>  в разделе «Доставка и оплата» .</p>
                <p className="document">4.3. Общая сумма Заказа, которая может включать платную доставку Товара, указывается в разделе « Оформить заказ».</p>
                <p className="document">Статья 5. Оплата товара</p>
                <p className="document">5.1. При безналичной форме оплаты обязанность Покупателя по уплате цены Товара считается исполненной с момента зачисления соответствующих денежных средств в размере 100% (ста процентов) предоплаты на расчетный счет Продавца по реквизитам, указанным в статье 12 (Реквизиты магазина) настоящего Договора.</p>
                <p className="document">5.2. Оплата он-лайн производится с помощью системы ЮKassa. Система предлагает Покупателю несколько вариантов оплаты Заказа.</p>
                <p className="document">5.3. Товары поставляются Покупателю по ценам, наименованию, в количестве, соответствующем счету, оплаченному Покупателем.</p>
                <p className="document">Статья 6. Доставка товара</p>
                <p className="document">6.1. Доставка Товара Покупателю осуществляется по адресу, указанные в виджете Почты России при выборе способа и адреса доставки Покупателем. В виджете указаны примерные сроки доставки и могут не значительно отличаться от действительного срока доставки.</p>
                <p className="document">6.2. Точная стоимость доставки Товара определяется в виджете Почты России при выборе способа и адреса доставки Покупателем. Стоимость доставки Заказа может быть изменена в том случае, если Покупатель изменит пункт доставки Заказа, предварительно согласовав изменения с менеджером Продавца.</p>
                <p className="document">6.3. Неявка Покупателя или не совершение иных необходимых действий для принятия Товара могут рассматриваться Продавцом в качестве отказа Покупателя от исполнения Договора.</p>
                <p className="document">6.4. Продавец имеет право потребовать у лица, принимающего товар, документы, идентифицирующие личность, либо документ, подтверждающий заключение договора. В качестве таких документов также могут выступать номер Заказа, квитанция о подтверждении заказа через электронную почту, учетные данные покупателя.</p>
                <p className="document">Статья 7. Права и обязанности сторон</p>
                <p className="document">7.1. Продавец обязуется:</p>
                <p className="document">7.1.1. Не разглашать любую частную информацию Покупателя и не предоставлять доступ к этой информации третьим лицам, за исключением курьерских служб, осуществляющих доставку заказа, он-лайн систем оплаты Заказа.</p>
                <p className="document">7.1.2. Предоставить Покупателю возможность получения телефонных консультаций по телефонам, указанным на сайте магазина (<a href={ CLIENT_URL }>MKY</a>) . Объем консультаций ограничивается конкретными вопросами, связанными с выполнениями Заказа.</p>
                <p className="document">7.1.3. Продавец оставляет за собой право изменять настоящий Договор в одностороннем порядке до момента его заключения.</p>
                <p className="document">7.2. Покупатель обязуется:</p>
                <p className="document">7.2.1. До момента заключения Договора ознакомиться с содержанием договора-оферты и условиями конфиденциальности на сайте интернет-магазина (<a href={ CLIENT_URL }>MKY</a>).</p>
                <p className="document">7.2.2. Предоставлять достоверную информацию о себе (ФИО, контактные телефоны, адрес электронной почты) и реквизиты для доставки Товара.</p>
                <p className="document">7.2.3. Принять и оплатить Товар в указанные в настоящем Договоре сроки.</p>
                <p className="document">Статья 8. Ответственность сторон и разрешение споров</p>
                <p className="document">8.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение настоящего Договора в порядке, предусмотренном настоящим Договором и действующим законодательством РФ.</p>
                <p className="document">8.2. Продавец не несет ответственности за доставку Заказа, если Покупателем указана недостоверная информация.</p>
                <p className="document">8.3. Продавец не несет ответственности, если ожидания Покупателя о потребительских свойствах Товара оказались не оправданны.</p>
                <p className="document">8.4. Продавец не несет ответственности за частичное или полное неисполнение обязательств по доставке Товара, если они являются следствием форс-мажорных обстоятельств.</p>
                <p className="document">8.5. Покупатель, оформляя Заказ, несет ответственность за достоверность предоставляемой информации о себе, а также подтверждает, что с условиями настоящего Договора ознакомлен и согласен.</p>
                <p className="document">8.6. Все споры и разногласия, возникающие при исполнении Сторонами обязательств по настоящему Договору, решаются путем переговоров, а также претензий, оформленных в письменном виде, направленных на электронную почту менеджера Продавца <a href={ CLIENT_URL }>MKY</a>. В случае невозможности их устранения, Стороны имеют право обратиться за судебной защитой своих интересов.</p>
                <p className="document">Статья 9. Возврат и обмен товара</p>
                <p className="document">9.1.Возврат товара</p>
                <p className="document">•	Персонализированные заказы (вышивки), возврату и обмену не подлежат, за исключением случая, если в товаре обнаружен производственный брак (транспортные расходы оплачивает <a href={ CLIENT_URL }>MKY</a>). Для того, чтобы не ошибиться с размером одежды, просим вас внимательно ознакомиться с Размерной сеткой.</p>
                <p className="document">•	В персонализированные заказы входят все вышивки представленные на сайте, а также вышивки по персональному дизайну Покупателя.</p>
                <p className="document">•   Отхождение нитей менее чем на 1 сантиметр является погрешностью и не может расцениваться как производственный брак.</p>
                <p className="document">В том случае, если в товаре был обнаружен производственный брак, транспортные расходы по обмену/возврату товара оплачивает <a href={ CLIENT_URL }>MKY</a>.</p>
                <p className="document">Для возврата товара вам необходимо написать на электронную почту <a href="mailto:support@mky-shop.ru&body=Опишите причину в свободной форме?subject=Возврат заказа">support@mky-shop.ru</a>. Темой "Возврат заказа". Прикрепите фото производственного брака товара и в свободной форме опишите обнаруженные дефекты. Наш менеджер ответит в течении 3-ёх рабочих дней.</p>
                <p className="document">Статья 10. Форс-мажорные обстоятельства</p>
                <p className="document">10.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые при данных условиях обстоятельства, препятствующие исполнению своих обязательств Сторонами по настоящему Договору. К ним относятся стихийные явления (землетрясения, наводнения и т. п.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т. п.), запретительные меры государственных органов (запрещение перевозок, валютные ограничения, международные санкции запрета на торговлю и т. п.). В течение этого времени Стороны не имеют взаимных претензий, и каждая из Сторон принимает на себя свой риск последствия форс-мажорных обстоятельств.</p>
                <p className="document">Статья 11. Срок действия договора</p>
                <p className="document">11.1. Настоящий Договор вступает в силу с момента оформления Заказа на сайте <a href={ CLIENT_URL }>MKY</a>, и заканчивается при полном исполнении обязательств Сторонами.</p>
            </div>
        </div>
    );
}
