import {Svg} from "../../components/svg/svg";
import {CreateOrderStep, LOCAL_STORAGE_NAMES} from "../../const/const";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {findOrder} from "../../store/api-action";
import {ReactComponentElement, useEffect} from "react";
import {onloadHandler} from "../loader/loader";
import ProgressBar from "../craete_order/progress_bar";

export default function OrderStatus () {
    const dispatcher = useAppDispatch();
    const createOrderStep = CreateOrderStep.payment;
    const sessionId = window.localStorage.getItem(LOCAL_STORAGE_NAMES.session);
    let offerInfo = useAppSelector(state => state.order);

    useEffect( () => {
        dispatcher(findOrder(sessionId? sessionId : ''));
    }, []);

    if (!offerInfo){
        return <div />;
    } else {
        onloadHandler();
    }

    let SVG: ReactComponentElement<any>;

    switch (offerInfo.payment.status) {
        case 'paid':
            SVG = <Svg type={'success'} />;
            break;
        case 'pending':
            SVG = <Svg type={'waiting'} />;
            break;
        default:
            SVG = <Svg type={'error'} />;
            break;
    }
    let delivery_type = ""
    if (offerInfo.delivery.delivery_company === 'russian_post') {
        delivery_type += 'Почта России | '
    } else {
        delivery_type += 'СДЕК | '
    }

    if (offerInfo.delivery.pvz_type === 'courier') {
        delivery_type += 'Курьерская служба'
    } else {
        delivery_type += 'Доставка в отделение'
    }

    return (
        <div className="main_container">
            <h1 className="cart_h1">Оформление заказа</h1>
            <ProgressBar step={createOrderStep}/>
            <div className="icon_handler payment_status_icon">
                {
                    SVG
                }
            </div>
            <div className="final_page black">
                <h2>{offerInfo.payment.status === 'paid' ? 'Спасибо за покупку !' : 'Ожидание оплаты...'}</h2>
                <h3>Номер заказа - <span className="black final_page_uniqid">{offerInfo.order.uniq_id}</span></h3>
                <br/>
                <div className="final_page_delivery">
                    <p className="final_page_text m-0">Доставка: {delivery_type}</p>
                    <p className="final_page_text m-0">Регион: {offerInfo.delivery.region}</p>
                    <p className="final_page_text m-0">Город: {offerInfo.delivery.city}</p>
                    <p className="final_page_text m-0">Адрес: {offerInfo.delivery.address}</p>
                </div>
                <br/>
                <p className="final_page_text">Вся информация по заказу будет приходить на вашу электронную почту <span
                    className="black">{offerInfo.order.email}</span></p>
                {
                    offerInfo.payment.status === 'paid' ?
                        <p className="final_page_text">Если письмо не пришло, проверьте папку спам</p> : ''
                }
                <p className="final_page_text">
                    При возникновении любых проблем с заказом, напишите нам на почту <a
                    href="mailto:support@mky-shop.ru" className="black">support@mky-shop.ru</a>
                </p>
            </div>
        </div>
    );
}
