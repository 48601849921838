import {useEffect} from "react";
import {CLIENT_URL, GlobalAppRoutes} from "../../../const/const";

export default function PrivacyPolicy () {
    useEffect(() => {
        document.title = 'MKY | Политика конфиденциальности';
    }, []);

    return (
        <div className="main_container">
            <h1 className="cart_h1">Политика конфиденциальности</h1>
            <div className="faq_container">
                <p className="document">Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «MKY», расположенный на доменном имени <a href={ CLIENT_URL }>mky-shop.ru</a>, может получить о Пользователе во время использования сайта Интернет-магазина, программ и продуктов Интернет-магазина.</p>
                <p className="document">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>
                <p className="document">1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
                <p className="document">1.1.1. “Администрация сайта Интернет-магазина” (далее – Администрация сайта) – уполномоченные сотрудники, действующие от имени самозанятого Малышев Артур Константинович, которые организуют и (или) осуществляет обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p className="document">1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому или юридическому лицу (субъекту персональных данных).</p>
                <p className="document">1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                <p className="document">1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
                <p className="document">1.1.5. «Пользователь сайта Интернет-магазина (далее   Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.</p>
                <p className="document">1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
                <p className="document">1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
                <p className="document">2. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p className="document">2.1. Использование Пользователем сайта Интернет-магазина означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
                <p className="document">2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта Интернет-магазина.</p>
                <p className="document">2.3. Настоящая Политика конфиденциальности применяется только к сайту Интернет-магазина <a href={ CLIENT_URL }>mky-shop.ru</a>. Интернет-магазин не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте Интернет-магазина.</p>
                <p className="document">2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта Интернет-магазина.</p>
                <p className="document">2.5. Сбор персональных данных, таких как ФИО, контактный номер, адрес доставки товара, электронная почта, осуществляется с согласия пользователя путем "отметки галочки", или нажатии кнопок "ОТПРАВИТЬ" или "ПОДПИСАТЬСЯ" при оформлении заказа в Интернет-магазине, заполнение формы обратной связи или подписки на новостную рассылку. Около каждой формы обратной связи или блока с персональными данными, есть ссылка на ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ с подробными условиями.</p>
                <p className="document">3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>
                <p className="document">3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта интернет-магазина по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте интернет-магазина или при оформлении Заказа для приобретения Товара.</p>
                <p className="document">3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте интернет-магазина <a href={ CLIENT_URL }>mky-shop.ru</a> в разделах «Оформление заказа», а также форм "Подписаться на рассылку" и форм обратной связи (расположенных на любых страницах данного сайта) и включают в себя следующую информацию:</p>
                <p className="document">3.2.1. фамилию, имя, отчество (ФИО) Пользователя;</p>
                <p className="document">3.2.2. контактный телефон Пользователя;</p>
                <p className="document">3.2.3. адрес электронной почты (e-mail);</p>
                <p className="document">3.2.4. адрес доставки Товара;</p>
                <p className="document">3.3. Интернет-магазин защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):</p>
                <p className="document">•	IP адрес;</p>
                <p className="document">•	информация из cookies;</p>
                <p className="document">•	информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</p>
                <p className="document">•	время доступа;</p>
                <p className="document">•	адрес страницы, на которой расположен рекламный блок;</p>
                <p className="document">•	реферер (адрес предыдущей страницы).</p>
                <p className="document">3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта Интернет-магазина, требующим авторизации.</p>
                <p className="document">3.4. Любая иная персональная информация не оговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
                <p className="document">4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</p>
                <p className="document">4.1. Персональные данные Пользователя Администрация сайта интернет-магазина может использовать в целях:</p>
                <p className="document">4.1.1. Идентификации Пользователя, зарегистрированного на сайте Интернет-магазина, для оформления заказа и (или) заключения Договора купли-продажи товара дистанционным способом с <a href={ CLIENT_URL }>mky-shop.ru</a>.</p>
                <p className="document">4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта интернет-магазина. (Личный кабинет, избранные товары)</p>
                <p className="document">4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта интернет-магазина, оказания услуг, обработка запросов и заявок от Пользователя.</p>
                <p className="document">4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                <p className="document">4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
                <p className="document">4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.</p>
                <p className="document">4.1.7. Уведомления Пользователя Сайта интернет-магазина о состоянии Заказа.</p>
                <p className="document">4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа.</p>
                <p className="document">4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта интернет-магазина.</p>
                <p className="document">4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернет-магазина или от имени партнеров Интернет-магазина.</p>
                <p className="document">4.1.11. Осуществления рекламной деятельности с согласия Пользователя.</p>
                <p className="document">4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров Интернет-магазина с целью получения продуктов, обновлений и услуг.</p>
                <p className="document">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</p>
                <p className="document">5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
                <p className="document">5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте интернет-магазина <a href={ CLIENT_URL }>mky-shop.ru</a>, включая доставку Товара, а также он-лайн платежным системам для оплаты Заказа.</p>
                <p className="document">5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
                <p className="document">5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
                <p className="document">5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                <p className="document">5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                <p className="document">6. ОБЯЗАТЕЛЬСТВА СТОРОН</p>
                <p className="document">6.1. Пользователь обязан:</p>
                <p className="document">6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом интернет-магазина.</p>
                <p className="document">6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
                <p className="document">6.2. Администрация сайта обязана:</p>
                <p className="document">6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
                <p className="document">6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</p>
                <p className="document">6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
                <p className="document">6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
                <p className="document">7. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                <p className="document">7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>
                <p className="document">7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
                <p className="document">7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
                <p className="document">7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
                <p className="document">7.2.3. Была разглашена с согласия Пользователя.</p>
                <p className="document">8. РАЗРЕШЕНИЕ СПОРОВ</p>
                <p className="document">8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                <p className="document">8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                <p className="document">8.3. При недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>
                <p className="document">8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</p>
                <p className="document">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>
                <p className="document">9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
                <p className="document">9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте интернет-магазина, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
                <p className="document">9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать, написав по электронному адресу <a href="mailto:admin@mky-shop.ru">admin@mky-shop.ru</a></p>
                <p className="document">9.4. Действующая Политика конфиденциальности размещена на странице по адресу <a href={ CLIENT_URL }>mky-shop.ru</a>, в разделе <a href={ GlobalAppRoutes.privacyPolicy }>«Политика конфиденциальности»</a>.</p>
            </div>
        </div>
    );
}
