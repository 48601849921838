import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {getCartData} from "../../store/api-action";
import {useEffect} from "react";
import priceStringify from "../../global_functions/price_stringify";
import {CartElemType, CreateOrderSteps, UserInfo} from "../../types/types";
import {CreateOrderStep} from "../../const/const";

export default function OrderTable( page: { step: CreateOrderSteps, userInfo: UserInfo | undefined } ) {
    const dispatcher = useAppDispatch();
    let data = useAppSelector(state => state.cartData);

    const cartData: (CartElemType)[] = [];
    data?.designs.forEach(elem => cartData.push(elem));
    data?.products.forEach(elem => cartData.push(elem));


    useEffect(() => {
        if (!data) {
            dispatcher(getCartData());
        }
    }, []);


    if (page.step === CreateOrderStep.payment) return <div />

    const offerData = {
        result: 0,
        discount: 0,
    };

    // расчёт стоимости доставки
    let deliveryPrice = 0;
    if (page.userInfo && page.userInfo.delivery) {
        deliveryPrice = page.userInfo.delivery.cashOfDelivery / 100;
    } else {
        deliveryPrice = 0;
    }

    // Подсчёт суммы заказа
    cartData.forEach(elem=> offerData.result += elem.price);
    offerData.result += deliveryPrice;

    return (
        <section className="order_table">
            <div className="order_table__cart">
                {
                    // TODO: Можно добавить хуепуталу. По клику на название товара можно сделать выпадающее (аккордеоном) меню с информацией о выбранной конфигурации. А в случае с доставкой, будет инфа по доставке.
                    cartData.map((elem) => {
                        return (
                        <div className="order_table__cart__item align-items-center" key={elem.cart_id}>
                            <p className="order_table__text">{elem.clothes_name}</p>
                            <p className="order_table__text">{ priceStringify( elem.price ) } ₽</p>
                        </div>
                        );
                    })
                }
                <OrderTableDelivery price={ deliveryPrice } />
            </div>
            <div className="d-flex justify-content-end">
                <p className="order_table__text">{ priceStringify( offerData.result) } ₽</p>
            </div>
        </section>
    );
}

function OrderTableDelivery ( data: {price: number} ) {
    if (data.price) {
        return (
            <div className="order_table__cart__item align-items-center" key="delivery">
                <p className="order_table__text">Доставка</p>
                <p className="order_table__text">{ priceStringify( data.price ) } ₽</p>
            </div>
        );
    }

    return <div />
}