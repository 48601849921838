import {getCartData} from "../../store/api-action";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {useEffect} from "react";
import CartOffer, {EmptyCartOffer} from "../../components/cart_offer/cart_offer";
import {AppNotification, AppRoute, errorState} from "../../const/const";
import EmptyCart from "../../components/empty_cart/empty_cart";
import {CartElemType } from "../../types/types";
import appNotification from "../../global_functions/notify";
import priceStringify from "../../global_functions/price_stringify";

export default function CartPage () {
    const dispatcher = useAppDispatch();

    useEffect(() => {
        document.title = 'MKY | Корзина';
    }, []);

    useEffect(() => {
        dispatcher(getCartData());
    }, []);
    let data = useAppSelector(state => state.cartData);

    const cartData: (CartElemType)[] = [];
    data?.designs.forEach(elem => cartData.push(elem));
    data?.products.forEach(elem => cartData.push(elem));

    const cartConf = {
       resultSum: 0,
       discountSum: 0
    };

    cartData?.forEach((elem) => {
        cartConf.resultSum += elem.price;
    });

    if (cartData.length === 0) {
        return <EmptyCart/>;
    }

    const incorrectPromo = () => {
        appNotification(AppNotification.info, "Промокод не действителен.");
    };

    return (
        <div className="main_container">
            <h1 className="cart_h1">Корзина</h1>
            <main className="cart">
                <section className="cart_main">
                    {
                        cartData.length !== 0 ? cartData.map((offerConf) => <CartOffer conf={offerConf} key={offerConf.cart_id}/>) : <EmptyCartOffer />
                    }
                </section>
                <aside className="cart_aside hidden">
                    <h4 className="black">
                        <span className="item_name">
                            <span>&nbsp;</span>
                            Итого:
                            <span>&nbsp;</span>
                        </span>
                        <span className="result_sum">{priceStringify(cartConf.resultSum)}</span> ₽
                    </h4>
                    <p className="cart_info-text">Скидка: <span className="discount_sum">{cartConf.discountSum}</span> ₽
                    </p>
                    <section className="cart_promocode">
                        <input type="text" name="promo" className="cart_form" placeholder="Введите промокод"/>
                        <input type="button" className="cart_form promo_button" onClick={incorrectPromo}
                               value="Применить"/>
                    </section>
                    <section className="cart_confirm">
                        <input type="button" className="cart_form cart_confirm-button" value="Перейти к оформлению" onClick={ () => { window.location.pathname = AppRoute.order } }/>
                    </section>
                </aside>
            </main>
        </div>
    );
}
