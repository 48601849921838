import Catalog, {EmptyCatalog} from "../../components/catalog/catalog";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import React, {useEffect} from "react";
import {getCatalog} from "../../store/api-action";
import {onloadHandler} from "../loader/loader";


export default function CatalogPage() {

    const { catalog_id } = useParams();
    const dispatcher = useAppDispatch();

    const thisCatalog = useAppSelector(( state ) => state.catalog );

    useEffect(()=>{
        if (catalog_id) {
            dispatcher(getCatalog({catalog_id: Number(catalog_id)}));
        }
    }, [dispatcher, catalog_id]);

    useEffect(() => {
        document.title = 'MKY | Каталог аниме одежды';
    }, []);


    if (!thisCatalog) {
        setTimeout(onloadHandler, 500);
        return (
            <div className="main_container loading">
                <h1 className="cart_h1">Каталог</h1>
                <div className="breadcrumbs">
                    <a href="/index.html"> Главная </a>
                </div>
                <div className="catalog_container" onLoad={onloadHandler}>
                    <EmptyCatalog />
                    <EmptyCatalog />
                    <EmptyCatalog />
                    <EmptyCatalog />
                    <EmptyCatalog />
                    <EmptyCatalog />
                </div>
            </div>
        );
    }

    // setTimeout(onloadHandler, 500);

    if (!thisCatalog.catalog.length && !thisCatalog.designs.length) {
        return (
            <div className="main_container loading">
                <h1 className="cart_h1">Каталог</h1>
                <div className="breadcrumbs">
                    <a href="/index.html"> Главная </a>
                    {
                        thisCatalog.path.map((crumb, index) =>
                            <span key={index}> -&gt; <a href={"/catalog/" + crumb.id}
                                                        className={thisCatalog.path.length - 1 === index ? 'bold' : ''}> {crumb.name} </a> </span>
                        )
                    }
                </div>
                <div className="catalog_container">
                    <p className="document">
                        Здесь пока ничего нет, но мы обязательно добавим сюда классные дизайны :)
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="main_container loading" onLoad={onloadHandler}>
            <h1 className="cart_h1">Каталог</h1>
            <div className="breadcrumbs">
                <a href="/index.html"> Главная </a>
                {
                    thisCatalog.path.map((crumb, index)=>
                        <span key={index}> -&gt; <a href={"/catalog/" + crumb.id} className={ thisCatalog.path.length - 1 === index ? 'bold' : '' }> {crumb.name} </a> </span>
                    )
                }
            </div>
            <div className="catalog_container">
                {
                    thisCatalog.catalog.map((catalog) =>
                        <Catalog photo={catalog.image} name={catalog.name} url={catalog.id} type={"catalog"} key={catalog.id} />
                    )
                }
                {
                    thisCatalog.products.map((catalog) => catalog === "ERROR" ? <div /> :
                        <Catalog photo={catalog.main_image} name={catalog.name} url={catalog.id} type={"product"} key={'product' + catalog.id} />
                    )
                }
                {
                    thisCatalog.designs.map((catalog) =>
                        <Catalog photo={catalog.image} name={catalog.name} url={catalog.id} type={"design"} key={'design' + catalog.id} />
                    )
                }
            </div>
        </div>
    );
}