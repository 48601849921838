import {ProductShort} from "../../types/types";
import {useState} from "react";
import {ApiRoute, changePhotoMethod, errorState} from "../../const/const";
import priceStringify from "../../global_functions/price_stringify";

export default function GeneratedElemPreview (data: {offerData?: ProductShort }) {

    const [state, setState] = useState(changePhotoMethod.main);

    if (!data.offerData || data.offerData === errorState) {
        return (
            <section className="item">
                <div className="item_link">
                    <div className="empty_clothes_img" />
                    <div className="empty_clothes_info">
                        <div className="empty_clothes_name" />
                        <div className="empty_clothes_price" />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="item">
            <a href={ ApiRoute.product + data.offerData.id } className="item_link">
                <div className="clothes_img" onMouseEnter={ () => setState(changePhotoMethod.secondary) } onMouseLeave={ () => setState(changePhotoMethod.main) } >
                    <img src={ state === changePhotoMethod.main ? data.offerData.main_image : data.offerData.design_image } alt="" />
                </div>
                <div className="clothes_info">
                    <div className="clothes_name"><h5 className="clothes_name__text">{ data.offerData.name }</h5></div>
                    <div className="clothes_price"><h5 className="clothes_price__text">{ priceStringify(data.offerData.price) } ₽</h5></div>
                </div>
            </a>
        </section>
    );
}
