import {AxiosInstance} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {AddProductToCart, AppDispatch, CreateOfferDataType, DesignParams, State} from '../types/types';
import {
    changeCartCount,
    createOffer,
    findOrderStatus,
    loadCartOffers,
    loadCatalog, loadDesign, loadProduct,
    setBestProduct,
    setRandomCatalogs
} from "./action";
import {ApiRoute, errorState, LOCAL_STORAGE_NAMES} from "../const/const";
import appNotification from "../global_functions/notify";

export const getBestProduct = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAll',
    async (none, {dispatch, extra: api}) => {
        api.get(ApiRoute.bestProduct).then(
            result => dispatch(setBestProduct(result.data)),
            error => appNotification('error', error.message)
        );
    }
);

export const getRandomCatalogs = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'catalog/random',
    async (none, {dispatch, extra: api}) => {

        api.get(ApiRoute.getRandomCatalogs).then(
            result => dispatch(setRandomCatalogs(result.data)),
            error => {
                dispatch(setRandomCatalogs(errorState));
                appNotification('error', error.message);
            }
        );
    }
);

export const getCartData = createAsyncThunk<void, undefined , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'cart/getAll',
    async (none, {dispatch, extra: api}) => {
        api.get(ApiRoute.cart).then(
            result => dispatch(loadCartOffers(result.data)),
            error => appNotification('error', error.message)
        );
    }
);

export const removeFromCart = createAsyncThunk<void, number , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'cart/remove',
    async (cart_id, {dispatch, extra: api}) => {
        api.post(ApiRoute.removeFromCart, { cart_id: cart_id }).then(
            result => {
                dispatch(loadCartOffers(result.data));
                dispatch(updateCartCount());
                appNotification("info", "Товар удалён из корзины.");
            },
            error => appNotification('error', error.message)
        );
    }
);


export const createOrder = createAsyncThunk<void, CreateOfferDataType, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAll',
    async (array, {dispatch, extra: api}) => {
        const { data } = await api.post(ApiRoute.createOrder, array);
        dispatch(createOffer(data.id));
        window.location.assign(data.link);
    }
);

export const updateCartCount = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'cart/count',
    async (none, {dispatch, extra: api}) => {
        api.get(ApiRoute.cartCount).then(
            result => {
                window.localStorage.setItem(LOCAL_STORAGE_NAMES.session, result.data.session_key);
                dispatch(changeCartCount(result.data.cart_count))
            },
            error => appNotification("error", error.message)
        );
    }
);

export const addDesignToCart = createAsyncThunk<void, DesignParams, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/addDesignToCart',
    async (array, {dispatch, extra: api}) => {
        const body : any = { design: array }

        api.post(ApiRoute.addDesignToCart, body).then(
            result => {
                dispatch(changeCartCount(result.data.cart_count))
                window.localStorage.setItem(LOCAL_STORAGE_NAMES.session, result.data.session_key);
                const button = document.querySelector('.button_waiting_query_result');
                button?.classList.remove('button_waiting_query_result');
                button?.classList.add('button_query_success');
                setTimeout(()=> button?.classList.remove('button_query_success'), 550);
                appNotification("info", "Товар добавлен в корзину!");
            },
            error => {
                const button = document.querySelector('.button_waiting_query_result');
                button?.classList.remove('button_waiting_query_result');
                button?.classList.add('button_query_error');
                appNotification("error", "Не получилось добавить в корзину =(");
                setTimeout(()=> button?.classList.remove('button_query_error'), 550);
            }
        );
    }
);

export const addProductToCart = createAsyncThunk<void, AddProductToCart, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/addProductToCart',
    async (array, {dispatch, extra: api}) => {

        const body : any = { product: array }

        api.post(ApiRoute.addProductToCart, body).then(
            result => {
                dispatch(changeCartCount(result.data.cart_count))
                window.localStorage.setItem(LOCAL_STORAGE_NAMES.session, result.data.session_key);
                const button = document.querySelector('.button_waiting_query_result');
                button?.classList.remove('button_waiting_query_result');
                button?.classList.add('button_query_success');
                setTimeout(()=> button?.classList.remove('button_query_success'), 550);
                appNotification("info", "Товар добавлен в корзину!");
            },
            error => {
                const button = document.querySelector('.button_waiting_query_result');
                button?.classList.remove('button_waiting_query_result');
                button?.classList.add('button_query_error');
                appNotification("error", "Не получилось добавить в корзину =(");
                setTimeout(()=> button?.classList.remove('button_query_error'), 550);
            }
        );
    }
);

export const findOrder = createAsyncThunk<void, string , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAll',
    async (none, {dispatch, extra: api}) => {
        api.get(ApiRoute.findOrder).then(
            result => dispatch(findOrderStatus( result.data )),
            error => appNotification("error", error.message)
        );
    }
);

export const getCatalog = createAsyncThunk<void, {catalog_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/catalog',
    async ({catalog_id}, {dispatch, extra: api}) => {
        api.get(ApiRoute.catalog + catalog_id).then(
            result => dispatch(loadCatalog( result.data )),
            error => appNotification("error", error.message)
        );
    }
);

export const getDesign = createAsyncThunk<void, {design_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/design',
    async ({design_id}, {dispatch, extra: api}) => {
        api.get(ApiRoute.design + design_id).then(
            result => dispatch(loadDesign( result.data )),
            error => appNotification("error", error.message)
        );
    }
);

export const getProduct = createAsyncThunk<void, {product_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/product',
    async ({product_id}, {dispatch, extra: api}) => {
        api.get(ApiRoute.product + product_id).then(
            result => dispatch(loadProduct( result.data )),
            error => appNotification("error", error.message)
        );
    }
);
