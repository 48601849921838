import {CartElemType} from '../../types/types';
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {getCartData, removeFromCart} from "../../store/api-action";
import priceStringify from "../../global_functions/price_stringify";
import {AppRoute} from "../../const/const";

export default function CartOffer (cartData: { conf: CartElemType  }) {
    const dispatcher = useAppDispatch();
    const price = priceStringify(cartData.conf.price);

    const cartElem = {
        isProduct: cartData.conf.is_product,
        id: cartData.conf.cart_id,
        photo: cartData.conf.photo,
        clothesSize: cartData.conf.clothes_size,
        clothesName: cartData.conf.clothes_name,
        price: priceStringify(cartData.conf.price),
        discount: priceStringify(cartData.conf.discount),
        pathToElem: cartData.conf.design_id? AppRoute.designs + cartData.conf.design_id : AppRoute.products + cartData.conf.product_id,
        designName: cartData.conf.design_name,
        designPlace: cartData.conf.design_place,
        designSize: cartData.conf.design_size
    }

    return (
        <article className="cart_item">
            <a href={ cartElem.pathToElem }><img src={ cartElem.photo } className="cart_item__image" alt="" /></a>
                <div className="text">
                    <h3><span className="item_name">{cartElem.designName}</span></h3>
                    <p className="cart_info-text">Размер: <span className="size">{cartElem.clothesSize}</span></p>
                    <p className="cart_info-text">Одежда: <span>{cartElem.clothesName}</span></p>
                    {cartElem.isProduct ? <div/> : <p className="cart_info-text">Расположение: <span>{cartElem.designPlace}</span></p>}
                    {cartElem.isProduct ? <div/> : <p className="cart_info-text">Размер вышивки: <span>{cartElem.designSize}</span></p>}
                    <h4 className="black">{price} ₽</h4>
                </div>
                <div className="cart_button_panel">
                    <button className="cart_button remove_this" onClick={ () => {dispatcher(removeFromCart(cartElem.id))} } >х</button>
                </div>
        </article>
    );
}

export function EmptyCartOffer () {
    return (
        <article className="cart_item">
            <div className="empty cart_item__image" />
            <div className="empty_text">
                <div className="empty_h w-75" />
                <div className="cart_info-text empty w-25" />
                <div className="cart_info-text empty w-50" />
                <div className="cart_info-text empty w-25" />
                <div className="cart_info-text empty w-50" />
                <div className="empty_h w-50" />
            </div>
            <div className="cart_button_panel" />
        </article>
    );
}
