import {onloadHandler} from "../loader/loader";
import {useEffect} from "react";

export default function AboutUsPage () {
    useEffect(() => {
        document.title = 'MKY | О нас';
    }, []);
    return (
        <div className="main_container loading" onLoad={onloadHandler}>
            <h1 className="cart_h1">О нас</h1>
        </div>
    );
}
