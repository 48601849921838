import {DeliveryProps, UserInfo} from "../../types/types";
import {addBuyerInfo} from "../../store/action";
import appNotification from "../../global_functions/notify";
import {
    cdek_courier_address_props,
    cdek_delivery_props,
    cdek_delivery_summary,
    cdek_pvz_address_props
} from "../../types/cdek_types";

// Функция выделения кнопки по её ID
export function ViewSelectedButton (id: string) {
    const button = document.getElementById(id);

    if (button && !button.classList.contains('active')) {
        document.querySelectorAll(".delivery_component .cart_form").forEach((el) => el.classList.remove('active'));
        button.classList.add('active');
    }
}

// Функция вставки всей хуйни в состояние
export function setDeliveryData(actualUserInfoState: UserInfo | undefined, dispatcher: any, originalDeliveryDataFromAPI: any) {

    if (!actualUserInfoState) {
        appNotification("error", "Ошибка предыдущего этапа (отсутствует информация о пользователе.)")
        actualUserInfoState = {
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            middleName: '',
            delivery: null
        }
    }

    if (originalDeliveryDataFromAPI) {
        const data = UniversalDeliveryDataDefinition(originalDeliveryDataFromAPI)
        if (!data.pvzType) {
            data.pvzType = "courier"
        }
        dispatcher(addBuyerInfo({
            ...actualUserInfoState,
            delivery: data
        }));
    }
}

export function CdekOnChooseCallback(
    delivery_type: string,
    delivery_props: cdek_delivery_props,
    address: cdek_pvz_address_props | cdek_courier_address_props,
    actualUserInfoState: UserInfo | undefined,
    dispatcher: any
){
    const data = {delivery_type: delivery_type, delivery_props: delivery_props, address: address};
    setDeliveryData(actualUserInfoState, dispatcher, data);
}

function UniversalDeliveryDataDefinition (deliveryDataFromAPI: cdek_delivery_summary | any): DeliveryProps {
    // CDEK
    if (deliveryDataFromAPI.delivery_type && deliveryDataFromAPI.delivery_props && deliveryDataFromAPI.address) {
        let indexTo = 0
        if (deliveryDataFromAPI.address.postal_code !== null && deliveryDataFromAPI.address.postal_code.length > 5) {
            indexTo = deliveryDataFromAPI.address.postal_code;
        }
        return {
            deliveryService: "CDEK",
            mailType: deliveryDataFromAPI.delivery_props.tariff_name,
            pvzType: deliveryDataFromAPI.address.type? deliveryDataFromAPI.address.type : "courier",
            indexTo: indexTo,
            cashOfDelivery: deliveryDataFromAPI.delivery_props.delivery_sum * 100,
            deliveryDescription: deliveryDataFromAPI.delivery_props.tariff_description,
            delivery: undefined,
            regionTo: deliveryDataFromAPI.address.region ? deliveryDataFromAPI.address.region : deliveryDataFromAPI.address.components[2].name,
            areaTo: deliveryDataFromAPI.address.region ? deliveryDataFromAPI.address.region : deliveryDataFromAPI.address.components[3].name,
            cityTo: deliveryDataFromAPI.address.city ? deliveryDataFromAPI.address.city : deliveryDataFromAPI.address.components[4].name,
            addressTo: deliveryDataFromAPI.address.address ? deliveryDataFromAPI.address.address : deliveryDataFromAPI.address.formatted,
            weight: 1,
            location: deliveryDataFromAPI.address.location ? deliveryDataFromAPI.address.location : deliveryDataFromAPI.address.position,
            comment: deliveryDataFromAPI.address.work_time,
        }
    } else {
        // Russian Post
        return {
            deliveryService: "russian_post",
            mailType: deliveryDataFromAPI.mailType,
            pvzType: deliveryDataFromAPI.pvzType,
            indexTo: deliveryDataFromAPI.indexTo,
            cashOfDelivery: deliveryDataFromAPI.cashOfDelivery,
            deliveryDescription: deliveryDataFromAPI.deliveryDescription,
            delivery: deliveryDataFromAPI.delivery,
            regionTo: deliveryDataFromAPI.regionTo,
            areaTo: deliveryDataFromAPI.areaTo,
            cityTo: deliveryDataFromAPI.cityTo,
            addressTo: deliveryDataFromAPI.addressTo,
            weight: deliveryDataFromAPI.weight,
            location: deliveryDataFromAPI.location,
            comment: deliveryDataFromAPI.comment ? deliveryDataFromAPI.comment : undefined,
        }
    }
}