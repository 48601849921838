import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {API_URL, LOCAL_STORAGE_NAMES} from "../const/const";


const REQUEST_TIMEOUT = 5000;
const currentSession = window.localStorage.getItem(LOCAL_STORAGE_NAMES.session);

export const createAPI = (): AxiosInstance => {
    const api = axios.create({
        baseURL: API_URL,
        timeout: REQUEST_TIMEOUT,
        headers: {"Session": currentSession? currentSession : false}
    });

    api.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            return config;
        },
    );

    return api;
};
