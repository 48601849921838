import {useAppDispatch} from "../../hooks/redux/redux";
import PhoneInput from 'react-phone-number-input'
import {UserInfo} from "../../types/types";
import {useEffect, useState} from "react";
import {addBuyerInfo} from "../../store/action";

export default function CreateOrderPersonality ( data: { userConfig: UserInfo | undefined } ) {

    const defaultState: UserInfo = {
        firstName: '',
        lastName: '',
        number: '',
        email: '',
        middleName: '',
        delivery: null
    };

    let [state, setState] = useState(defaultState);
    useEffect(()=>{
        if (data.userConfig) {
            setState(data.userConfig);
        }
    }, [data.userConfig]);

    const dispatcher = useAppDispatch();

    const numberInputHandler = (evt: any) => {
        const data = {
            ...state,
            number: evt
        };
        setState(data);
        dispatcher(addBuyerInfo(data));
    }

    const firstNameInputHandler = (evt: any) => {
        const data = {
            ...state,
            firstName: evt.target.value
        };
        setState(data);
        dispatcher(addBuyerInfo(data));
    }

    const lastNameInputHandler = (evt: any) => {
        const data = {
            ...state,
            lastName: evt.target.value
        };
        setState(data);
        dispatcher(addBuyerInfo(data));
    }

    const middleNameInputHandler = (evt: any) => {
        const data = {
            ...state,
            middleName: evt.target.value
        };
        setState(data);
        dispatcher(addBuyerInfo(data));
    }

    const emailInputHandler = (evt: any) => {
        const data = {
            ...state,
            email: evt.target.value
        };
        dispatcher(addBuyerInfo(data));
    }

    return (
        <form className="order_form">
            <input type="text" placeholder="Имя" id="name" className="cart_form" onChange={firstNameInputHandler} value={state.firstName} />
            <input type="text" placeholder="Фамилия" id="lastname" className="cart_form" onChange={lastNameInputHandler} value={state.lastName} />
            <input type="text" id="middlename" placeholder="Отчество" className="cart_form order_input_last" onChange={middleNameInputHandler} value={state.middleName} />
            <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="RU"
                placeholder={"Номер телефона"}
                countries={['RU']}
                onChange={numberInputHandler}
                value={data.userConfig?.number}
            />
            <input type="email" placeholder="Email" id="email" className="cart_form" onChange={emailInputHandler} value={state.email} />
        </form>
    );
}

