import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {GlobalAppRoutes, InstagramURL, TelegramURL, VkontakteURL} from "../../const/const";
import {useParams} from "react-router-dom";
import {updateCartCount} from "../../store/api-action";

export default function Header ():JSX.Element {
    const cartCount = useAppSelector(state => state.cartCount);

    const dispatcher = useAppDispatch();

    if (cartCount === 0) {
        dispatcher(updateCartCount());
    }

    const routeParams = useParams();
    const cartImg = routeParams.id ? "../styles/img/basket-shopping-solid.svg" : "/styles/img/basket-shopping-solid.svg";

    return(
        <div className="site-wrap" id="home-section">
            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3">
                        <span className="icon-close2 js-menu-toggle" />
                    </div>
                </div>
                <div className="site-mobile-menu-body" />
            </div>

            <header className="site-navbar js-sticky-header site-navbar-target" role="banner">

                <div className="header_container">
                    <div className="header_content align-items-center position-relative">
                        <div className="site-logo">
                            <a href="/" className="text-black logo-text"><span className="text-black">MKY</span></a>
                        </div>
                        <div className="col-12">
                            <nav className="site-navigation text-right ml-auto " role="navigation">

                                <ul className="site-menu main-menu js-clone-nav ml-auto d-none d-md-block">
                                    <li><a href="/catalog/1" className="nav-link">Одежда</a></li>

                                    {/*<li><a href="/about" className="nav-link">О нас</a></li>*/}

                                    <li><a href={ VkontakteURL } className="nav-link">VK</a></li>

                                    <li><a href={ TelegramURL } className="nav-link">Telegram</a></li>

                                    <li><a href={ InstagramURL } className="nav-link">Instagram</a></li>

                                    <li><a href="/faq" className="nav-link">FAQ</a></li>

                                    <li>
                                        <a href="/cart" className="nav-link">
                                            Корзина ( <span className="cart_count">{ cartCount }</span> )
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="toggle-button d-inline-block d-md-none">
                            <a href={ GlobalAppRoutes.cart } className="site-menu-toggle py-5 js-menu-toggle text-black">
                                <img width="25px" height="25px" src={cartImg} alt="cart" className="cart_image" />
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}
