import Catalog, {EmptyCatalog} from "../catalog/catalog";
import {useAppSelector} from "../../hooks/redux/redux";
import {errorState} from "../../const/const";
import {useEffect} from "react";
import {store} from "../../store";
import {getRandomCatalogs} from "../../store/api-action";

export default function CatalogCarusel() {
    useEffect(() => {
        store.dispatch(getRandomCatalogs());
    }, []);

    let randomCatalogs = useAppSelector(state => state.randomCatalogs);

    if (!randomCatalogs || randomCatalogs === errorState) {
        return (
            <section className="first_page__catalog_scroll">
                <EmptyCatalog />
                <EmptyCatalog />
                <EmptyCatalog />
                <EmptyCatalog />
                <EmptyCatalog />
                <EmptyCatalog />
                <EmptyCatalog />
            </section>
        )
    } else {
        return (
            <section className="first_page__catalog_scroll">
                {
                    randomCatalogs.map((catalog, index) => {
                        return (<Catalog photo={catalog.image} type={"catalog"} name={catalog.name} url={catalog.id} key={'randomCatalog' + index}/>)
                    })
                }
            </section>
        )
    }
}