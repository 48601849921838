import {onloadHandler} from "../loader/loader";
import {useEffect} from "react";
import FirstPagePreview from "../../components/first_page_preview/first_page_preview";
import CatalogCarusel from "../../components/catalog_carusel/catalog_carusel";


export default function FirstPage() {

    useEffect(() => {
        document.title = 'MKY | Интернет магазин аниме вышивки на одежде';
    }, []);

    return (
        <div className="first_page loading" onLoad={onloadHandler}>
            <a href="/catalog/1">
                <img className="site-section bg-light" src="styles/img/main.png"/>
            </a>
            <section className="first_page__items__container">
                <FirstPagePreview />
            </section>
            <section className="first_page__about_us">
                <div className="header_container first_page__about_us__content">
                    <img src="styles/img/about_us3.png" alt="MKY"/>
                    <div className="first_page__about_us__text">
                        <p>
                            MKY - это культура. Китайцы едят мышей. Чурки ебут барашек. Евреи отрезают писюны.
                            А мы - делаем одежду с вышивкой. Не только с аниме персонажами, как могло показаться.
                            Это не те жалкие принты которые можно пальцем с футболки содрать.
                            Это конкретная вышивка, для нормальных пацанов.
                            Да - нам нравится такая хуйня.
                            Нравится что у нас собственное производство в России.
                            Нравится делать одежду, которую Вы носите! Ёпта) ♥
                        </p>
                    </div>
                </div>
            </section>
            <CatalogCarusel />
        </div>
    );
}
