import {DeliveryURL} from "../../../const/const";
import {CdekOnChooseCallback, ViewSelectedButton} from "../main_functions";
import {cdek_courier_address_props, cdek_delivery_props} from "../../../types/cdek_types";
import {UserInfo} from "../../../types/types";

export default function CdekCourierWidgetRenderer(evt: any, actualUserInfoState: UserInfo | undefined, dispatcher: any) {
    evt.preventDefault();
    ViewSelectedButton('2.2');
    const element: HTMLElement = document.getElementById('ecom-widget') as HTMLElement
    element.innerHTML = ''
    element.style.height = '0';
    const cdek_map: HTMLElement = document.getElementById('cdek-map') as HTMLElement
    cdek_map.style.height = '500px';
    // @ts-ignore
    new window.CDEKWidget({
        from: {
            country_code: 'RU',
            city: 'Казань',
            postal_code: 420124,
            code: 424,
            address: 'Россия, Республика Татарстан (Татарстан), Казань, улица Абсалямова, 28',
        },
        root: 'cdek-map',
        apiKey: '60549c9a-85c1-4c8d-9a12-051707afbe67',
        servicePath: DeliveryURL.Cdek.widget,
        defaultLocation: 'Казань',
        hideDeliveryOptions: {
            door: false,
            office: true,
        },
        onChoose: (delivery_type: string, delivery_props: cdek_delivery_props, address_props: cdek_courier_address_props)=> CdekOnChooseCallback(delivery_type, delivery_props, address_props, actualUserInfoState, dispatcher),
        hideFilters: {
            have_cashless: true,
            have_cash: true,
            is_dressing_room: true,
            type: true,
        },
        sender: false,
        debug: true,
        tariffs: {
            door: [137, 122, 482],
        },
        goods: [ {
            width: 20,
            height: 20,
            length: 20,
            weight: 1,
        }, ],
    });
}