import {useEffect} from "react";
import {GlobalAppRoutes} from "../../const/const";

export default function FaqPage () {
    useEffect(() => {
        document.title = 'MKY | FAQ';
    }, []);

    return (
        <div className="main_container">
            <h1 className="cart_h1">FAQ</h1>
            <div className="faq_container">
                <h3 className="cart_h1">Доставка</h3>
                <p className="faq_text">Доставляем нашу продукцию по всей России.<br />Доставка осуществляется при помощи компании Почта России.<br />
                    Если вы хотите получить посылку за пределами России, свяжитесь с нами по почте <a href="mailto:support@mky-shop.ru">support@mky-shop.ru</a></p>
            </div>
            <div className="faq_container">
                <h3 className="cart_h1">Сроки доставки</h3>
                <p className="faq_text">От 1 до 30 дней в зависимости от удаленности от г.Казань<br />В среднем доставка занимает 3-7 календарных дней и от двух до 7 дней на изготовление вышивки.</p>
            </div>
            <div className="faq_container">
                <h3 className="cart_h1">Оплата</h3>
                <p className="faq_text">Оплата происходит при помощи сервиса ЮКassa. У магазина MKY нет данных ваших карт!<br />
                    Мы сохраняем только ту информацию, которая необходима для скорейшей доставки заказа!
                </p>
            </div>
            <div className="faq_container">
                <h3 className="cart_h1">Возврат</h3>
                <p className="faq_text">Чтобы сделать возврат напишите нам на почу <a href="mailto:support@mky-shop.ru&body=Опишите причину в свободной форме?subject=Возврат заказа">support@mky-shop.ru</a><br />
                    • Персонализированные заказы (вышивки), возврату и обмену не подлежат, за исключением случаев, если в товаре обнаружен производственный брак (транспортные расходы оплачивает MKY). Для того, чтобы не ошибиться с размером одежды, просим вас внимательно ознакомиться с Размерной сеткой.<br />
                    • В персонализированные заказы входят все вышивки представленные на сайте, а также вышивки по персональному дизайну Покупателя.<br />
                    • Отхождение нитей менее чем на 1 сантиметр является погрешностью и не может расцениваться как производственный брак.<br />
                    В том случае, если в товаре был обнаружен производственный брак, транспортные расходы по обмену/возврату товара оплачивает MKY.<br />
                </p>
            </div>
            <p className="faq_text">Подробнее про работу интернет-магазина MKY - <a href={GlobalAppRoutes.publicOffer}>Публичная оферта</a> - <a href={GlobalAppRoutes.privacyPolicy}>Политика конфиденциальности</a></p>
        </div>
    );
}
